<template>
  <v-app class="background">
    <v-container>
      <v-layout row wrap justify-center align-center pt-16>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/SantolSorbetto.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg6>
                    <v-btn
                      block
                      outlined
                      rounded
                      color="brown accent-4"
                      @click="closeSantol = true"
                    >
                    Santol Sorbetto
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeSantol"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Fudge Brownie</p>
                    <p class="brown--text text-left pt-4">
                    กระท้อน ผลไม้ไทยที่ทำได้ทั้งอาหารคาวและขนมหวาน
              คราวนี้เราเลยนำกระท้อนที่ได้มาจากสวนคุณลุง จ.สุราษฎร์ธานี มาทำเจลาโต้รสกระท้อน
              เป็นเจลาโต้แบบซอร์เบท(sorbeto)ที่ใช้เนื้อกระท้อนเฉพาะที่เป็นปุยๆเท่านั้นทำให้รสชาติที่ได้มีความหวานฉ่ำอม
              เปรี้ยวนิดๆ และมีเนื้อกระท้อนนุ่มๆปนอยู่ด้วย ซึ่งโดยปกติของการทำเจลาโต้ซอร์เบท
              จะต้องมีน้ำเป็นส่วนประกอบเราจึงนำเนื้อกระท้อนมาคั้นน้ำเพื่อให้ได้รสกระท้อนธรรมชาติ
              ปราศจากกลิ่น และรสปรุงแต่ง
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeSantol = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/Durian.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg9>
                    <v-btn block outlined rounded color="brown accent-4" @click="closeDurian = true">
                      Durian Cheese Pie Gelato
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeDurian"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text"> Durian Cheese Pie Gelato</p>
                    <p class="brown--text text-left pt-4">
                     ทุเรียนกระดุมจากจังหวัดสุราษฎร์ธานี อำเภอบ้านนาสาร จุดเด่นคือรสหวานและเนื้อหอมอ่อนๆ
              เราเลือกนำมาทำเจลาโต้ โดยอาศัยความหวานของทุเรียนล้วนๆเป็นตัวชูรส ทานคู่กับครีมชีส
              พร้อมคัมเบิ้ล ที่เราออกแบบให้ เหมือนเป็นทาร์ตคล้ายกับชีสพาย จึงเป็นที่มาของชื่อเจลาโต้
              “รสทุเรียนชีสพาย”(Durian Cheese Pie Gelato)
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeDurian = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
export default {
  components: {},
  data: () => ({
    closeSantol: false,
    closeDurian: false
  })
}
</script>
<style scoped>
.background {
  background-color: #edede7;
}
</style>
