<template>
  <v-app class="background">
    <v-container>
      <v-layout row wrap justify-center align-center pt-16>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/PineapplePassionFruit.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg8>
                    <v-btn
                      block
                      outlined
                      rounded
                      color="brown accent-4"
                      @click="closePineapple = true"
                    >
                      Pineapple Passion Fruit
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closePineapple"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Pineapple Passion Fruit</p>
                    <p class="brown--text text-left pt-4">
                      ช่วงโควิดหลายคนคงได้ยินข่าวพืชผลทางการเกษตรตกต่ำ
                      ผลไม้หน้าสวนขายกันโลละไม่กี่บาท ชาวสวนและเกษตรกรต่างเดือดร้อน
                      เนื่องจากสินค้าล้นตลาด ขายไม่ได้ราคา
                      และปัญหาเศรษฐกิจที่ทุกคนต่างรู้กันดีว่าซบเซาขนาดไหน แถมยังมีดราม่าเรื่อง food
                      waste เข้ามาเกี่ยวอีก ด้วยเหตุผลข้างต้น เราจึงได้นำเสาวรสจากสวนป้าพร
                      ซึ่งเป็นสวนแบบเกษตรอินทรีย์ในจังหวัดกระบี่ มาแปรรูปและรังสรรค์เป็นเจลาโต้รส
                      Pineapple Passion Fruit ขึ้นมา เป็นการสร้างมูลค่าให้แก่สินค้าเกษตร
                      และช่วยให้คุณป้าพอมีรายได้อย่างต่อเนื่อง มีกำลังใจในการปลูก ถึงแม้ว่า rest is
                      more เราจะเป็นเพียงร้านเจลาโต้โฮมเมดเล็กๆ ไม่ได้มีกำลังการผลิตมากมาย
                      แต่เรามีจุดมุ่งหมายและจุดยืนที่ชัดเจน
                      ว่าเราต้องการเป็นส่วนหนึ่งในการขับเคลื่อนสังคมและคุณภาพชีวิตของคนในชุมชนให้เดินหน้าต่อไปได้
                      รวมถึงส่งเสริมด้านการเกษตรของคนในพื้นที่ต่างๆ
                      สุดท้ายขอขอบคุณผู้สนับสนุนเบื้องหลัง อย่างร้าน เจ๊มะนาว ปูไข่ดองน้ำปลากวน
                      ที่ได้นำเจลาโต้รสชาตินี้ไปขายที่ร้านด้วย
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closePineapple = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/HoneyLemonSorbetto.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg8>
                    <v-btn block outlined rounded color="brown accent-4" @click="closeHoney = true">
                      Honey Lemon Sorbetto
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeHoney"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Honey Lemon Sorbetto</p>
                    <p class="brown--text text-left pt-4">
                      รสสัมผัสแรกของซอร์เบทโต้รส Honey Lemon คือ รสเปรี้ยวกลมกล่อมจากผลเลมอนสด
                      ตั้งแต่คำแรกที่ทาน แต่เมื่อคุณกลืนลงไป กลับได้รสหวานของน้ำผึ้งแทรกอยู่ในคอ
                      เปรียบเหมือน aftertaste พร้อมกลิ่นหอมของผิวเลมอนที่เรานำไป infuse ในเบส
                      ยังเป็นการเพิ่มอรรถรสความซับซ้อนของซอร์เบทโต้รสนี้ และเนื้อสัมผัสที่เนียน นุ่ม
                      ไม่เป็นเกล็ดน้ำแข็ง ช่วยให้ได้รสชาติที่เด่นชัด สมกับชื่อ Honey Lemon Sorbetto
                      จริงๆ
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeHoney = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/YuzuMAndarinSorbetto.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg9>
                    <v-btn block outlined rounded color="brown accent-4" @click="closeYuzu = true">
                      Yuzu x MAndarin Sorbetto
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeYuzu"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Yuzu x MAndarin Sorbetto</p>
                    <p class="brown--text text-left pt-4">
                      Concept : Luxury taste & Local orange Luxury taste :
                      รสพรีเมียมของส้มยุสุที่นำเข้าจากเกาหลี ( 유자 )
                      ด้วยรสชาติที่เปรี้ยวอ่อนๆละมุนๆ มีกลิ่นหอมสดชื่นที่ใครๆก็ต่างหลงรัก
                      เอกลักษณ์เฉพาะที่หาจากส้มที่ไหนไม่ได้ Local orange : ส้มแมนดาริน รสชาติหวานฉ่ำ
                      ตัดรสเปรี้ยวของส้มยุสุจนได้รสเปรี้ยวอมหวานชัดเจน
                      การนำส้มทั้งสองชนิดมาทำเจลาโต้ เราคงรสและกลิ่นของส้มไว้ทั้งหมด
                      เพื่อให้คุณได้กลิ่นอายทรอปิคอล ความมีชีวิตชีวา ความสดใส
                      เหมือนคุณได้ทานเจลาโต้เย็นๆท่ามกลางแสงอาทิตย์อบอุ่น
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeYuzu = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
export default {
  components: {},
  data: () => ({
    closePineapple: false,
    closeHoney: false,
    closeYuzu: false
  })
}
</script>
<style scoped>
.background {
  background-color: #edede7;
}
</style>
