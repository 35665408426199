<template>
  <v-app class="background">
    <Toolbar />
    <v-container>
      <v-layout row wrap justify-center align-center pt-16>
        <v-flex xs11 lg12>
              <v-tabs v-model="tab"  color="#9c7656" :vertical="$vuetify.breakpoint.smAndDown">
            <v-tab v-for="item in itemsTab" :key="item.tab"> {{ item.tab }}</v-tab>
          </v-tabs>
                  <v-tabs-items v-model="tab">
            <!-- -----------------------------tab 1----------------------------- -->
            <v-tab-item>
              <AllGelato />
            </v-tab-item>
            <!-- -----------------------------tab 2----------------------------- -->
            <v-tab-item>
              <Gelato />
            </v-tab-item>
            <!-- -----------------------------tab 3----------------------------- -->
            <v-tab-item>
              <SorbettoGelato />
            </v-tab-item>
            <!-- -----------------------------tab 4----------------------------- -->
            <v-tab-item>
              <SpecialFlavoursGelato />
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- <v-layout row wrap pb-2>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
        <path
          fill="#9C7656"
          fill-opacity="1"
          d="M0,224L48,218.7C96,213,192,203,288,208C384,213,480,235,576,218.7C672,203,768,149,864,138.7C960,128,1056,160,1152,176C1248,192,1344,192,1392,192L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </v-layout> -->

    <Footer />
  </v-app>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import Footer from '@/components/Footer.vue'
import Gelato from '@/views/pages/DetailGelato/Gelato.vue'
import SorbettoGelato from '@/views/pages/DetailGelato/SorbettoGelato.vue'
import SpecialFlavoursGelato from '@/views/pages/DetailGelato/SpecialFlavoursGelato.vue'
import AllGelato from '@/views/pages/DetailGelato/AllGelato.vue'
export default {
  components: {
    Toolbar,
    Footer,
    AllGelato,
    Gelato,
    SorbettoGelato,
    SpecialFlavoursGelato
  },
  data: () => ({
    tab: null,
    show: false,
    showCookie: false,
    showToffee: false,
    showMatcha: false,
    showBlueberry: false,
    showCrunchy: false,
    showArtisan: false,
    itemsTab: [
      { tab: 'All' },
      { tab: 'Gelato' },
      { tab: 'Sorbetto Gelato' },
      { tab: 'Special Flavours Gelato' }
    ]
  })
}
</script>
<style scoped>
.fontHeader {
  font-size: 21px;
  color: #9c7656;
}
.bottom {
  padding-bottom: 370px;
}
.bottomLayoutTwo {
  padding-bottom: 800px;
}
.bottomLayoutThree {
  padding-bottom: 630px;
}
.background {
  background-color: #EDEDE7;
}
</style>
