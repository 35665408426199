<template>
  <v-app class="background">
    <v-layout row wrap justify-center align-center pt-16>
      <v-flex xs10 lg4 pa-2>
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="344"
          >
            <v-card-title primary-title>
              <v-img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                src="@/assets/img/PremiumVanilla.jpg"
              ></v-img>
            </v-card-title>
            <v-card-actions class="pb-6">
              <v-layout row wrap justify-center align-center>
                <v-flex xs10 lg8>
                  <v-btn block outlined rounded color="brown accent-4" @click="closeBrownie = true">
                    Premium Vanilla Gelato
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="closeBrownie"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4 brown--text">Premium Vanilla Gelato</p>
                  <p class="brown--text text-left pt-4">
                    <b> Fudge Brownies</b> หากเอ่ยถึงเจลาโต้รสวานิลลา
                    หลายๆคนคงคุ้นตากับเนื้อไอศกรีมสีเหลืองพาสเทล แตกต่างจากเจลาโต้ของเรา
                    ที่รสวานิลลากลับเป็นสีขาวนวล ตัดกับจุดดำๆเล็กๆของฝักวานิลลา
                    ที่ปนอยู่ในเนื้อไอศกรีม ซึ่งนั่นเป็นเพราะส่วนผสมหลักของเราคือนมสด
                    ไม่มีการผสมไข่แดง กลิ่นรสของวานิลลาช่วยเพิ่มความหวานหอมที่ละมุนไปทั่วปาก
                    **การใส่ไข่แดงเพื่อช่วยในการจับตัวของเนื้อไอศกรีมทำให้เนื้อไอศกรีมเนียนและเหนียว
                    แต่เนื่องจากไข่มีแบคทีเรีย Salmonella ที่อาจเกิดในกระบวนการทำไอศกรีม
                    ทางร้านจึงเลือกที่จะไม่ใช้ค่ะ
                  </p>
                </v-card-text>
                <v-card-actions class="pt-3">
                  <v-btn outlined rounded color="brown accent-4" @click="closeBrownie = false">
                    ปิด
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-hover>
      </v-flex>
      <v-flex xs10 lg4 pa-2>
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="344"
          >
            <v-card-title primary-title>
              <v-img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                src="@/assets/img/DarkChocolate.jpg"
              ></v-img>
            </v-card-title>
            <v-card-actions class="pb-6">
              <v-layout row wrap justify-center align-center>
                <v-flex xs10 lg8>
                  <v-btn block outlined rounded color="brown accent-4" @click="closeChewy = true">
                    70% Dark Chocolate
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="closeChewy"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4 brown--text">70% Dark Chocolate</p>
                  <p class="brown--text text-left pt-4">
                    เรารู้สึกว่าเจลาโต้ช็อกโกแลตเป็นรสชาติเบสิคที่ทุกร้านต้องมีเราไม่ได้คิดจะทำให้รสชาติแตกต่างเพราะจุดที่เราโฟกัสคือคุณภาพของช็อกโกแลตที่เราเลือกใช้
                    ซึ่งเป็นดาร์กช็อกโกแลต 70% และโกโก้เข้มข้นของทางร้านเอง
                    เราว่าส่วนผสมที่เราใส่แบบไม่กักช่วยสร้างให้รสดาร์กช็อกโลแลตมีคาแรกเตอร์ด้วยตัวมันเอง
                    คือ หวานปนขม บวกกับเนื้อไอศกรีมที่แน่น
                    ยังช่วยชูรสดาร์กช็อกโกแลตให้เด่นชัดมากขึ้นไปอีก
                  </p>
                </v-card-text>
                <v-card-actions class="pt-3">
                  <v-btn outlined rounded color="brown accent-4" @click="closeChewy = false">
                    ปิด
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-hover>
      </v-flex>
      <v-flex xs10 lg4 pa-2>
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="344"
          >
            <v-card-title primary-title>
              <v-img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                src="@/assets/img/Matcha.jpg"
              ></v-img>
            </v-card-title>
            <v-card-actions class="pb-6">
              <v-layout row wrap justify-center align-center>
                <v-flex xs10 lg6>
                  <v-btn block outlined rounded color="brown accent-4" @click="closeDuo = true">
                    Matcha Gelato
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="closeDuo"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4 brown--text">Matcha Gelato</p>
                  <p class="brown--text text-left pt-4">
                    เราเชื่อเสมอว่า ‘วัตถุดิบที่ดี สามารถรังสรรค์รสชาติให้ออกมาดีได้’
                    ตัวเราเองเมื่อก่อนก็ไม่ค่อยอินกับมัทฉะ เท่าไหร่นัก เราจึงใช้เวลาพอสมควร
                    ในการพาตัวเองเข้าไปรู้จัก ศึกษาที่มาของวัตถุดิบทุกอย่าง
                    เราจึงเริ่มเปิดใจให้มัทฉะ โดยลองชิมมัทฉะจากหลายๆที่ ที่นำเข้ามาจากญี่ปุ่น
                    จนเราเริ่มคุ้นชินกับรสของมัน ทำให้เราเข้าใจความชอบและไม่ชอบของคนอื่นมากขึ้น
                    เราจึงอยากทำ Matcha Gelato ที่ทุกคนสามารถทานได้ เป็นรสชาติกลางๆ
                    ที่ยังคงความอูมามิของตัวมัทฉะเอาไว้ ด้วยรสชาติที่จะออกรสขมฝาดๆ
                    แต่มีความหวานอ่อนๆ เราอยากจะส่งต่อรสชาติของมัทฉะดีๆ ให้คนอื่นๆอยู่ ผ่านทาง
                    Matcha Gelato
                  </p>
                </v-card-text>
                <v-card-actions class="pt-3">
                  <v-btn outlined rounded color="brown accent-4" @click="closeDuo = false">
                    ปิด
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center align-center pt-10>
      <v-flex xs10 lg4 pa-2>
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="344"
          >
            <v-card-title primary-title>
              <v-img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                src="@/assets/img/Cookie&Cream.jpg"
              ></v-img>
            </v-card-title>
            <v-card-actions class="pb-6">
              <v-layout row wrap justify-center align-center>
                <v-flex xs10 lg8>
                  <v-btn block outlined rounded color="brown accent-4" @click="closeBanana = true">
                    Cookie & Cream Gelato
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="closeBanana"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4 brown--text">Cookie & Cream Gelato</p>
                  <p class="brown--text text-left pt-4">
                    หากมองย้อนไปในสมัยเด็ก ไอศกรีมรสคุกกี้แอนด์ครีม เป็นรสที่เราโปรดปรานมากที่สุด
                    แทบจะทุกวันหลังเลิกเรียน เราจะสั่งไอศกรีมใส่โคนรสคุกกี้แอนด์ครีมทุกครั้ง
                    ไอศกรีมสีขาวที่มีโอริโอ้ล้อมรอบ ถูกวางอยู่บนโคนน้อยๆ
                    ที่เรากลัวทุกครั้งว่ามันจะหล่นลงพื้นมั้ยนะ
                    พร้อมลุ้นอีกว่าวันนี้ไอศกรีมจะละลายถึงก้นโคน หรือว่าเราจะกินมันหมดก่อน
                    เหลือเพียงโคนกลวงๆ (ซึ่งสุดท้ายเราก็กินหมดก่อนที่มันจะละลายทุกที )
                    เราชอบรสชาติของนมจืดเย็นๆ ที่ถูกนำมาเป็นไอศกรีม
                    และเท็กซ์เจอร์ของโอริโอ้ที่อยู่ในนม ซึ่งบางชิ้นกรอบ บางชิ้นก็นิ่ม ผสมๆกันไป
                    จนได้อินสปายจากความชอบในวัยเด็ก มาทำเป็นเจลาโต้รสคุกกี้แอนด์ครีมของที่ร้านขึ้นมา
                    แต่สิ่งที่พิเศษคือ’ฝักวานิลลา’ ที่เรานำมาใส่ในนมด้วย ทำให้ได้ความหอมอ่อนๆ
                    พร้อมวิปปิ้งครีมที่ช่วยเพิ่มความหวานมัน พอทานเจลาโต้รสนี้ทีไร
                    ก็นึกถึงตัวเองในวัยเด็กทุกที
                    แน่นอนว่าเรายังทานเจลาโต้หมดก่อนที่มันจะละลายเหมือนเดิม
                  </p>
                </v-card-text>
                <v-card-actions class="pt-3">
                  <v-btn outlined rounded color="brown accent-4" @click="closeBanana = false">
                    ปิด
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-hover>
      </v-flex>
      <v-flex xs10 lg4 pa-2>
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="344"
          >
            <v-card-title primary-title>
              <v-img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                src="@/assets/img/ToffeeCoffee.jpg"
              ></v-img>
            </v-card-title>
            <v-card-actions class="pb-6">
              <v-layout row wrap justify-center align-center>
                <v-flex xs10 lg8>
                  <v-btn block outlined rounded color="brown accent-4" @click="closeBael = true">
                    Toffee Coffee Gelato
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="closeBael"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4 brown--text">Toffee Coffee Gelato</p>
                  <p class="brown--text text-left pt-4">
                    “คาราเมล”หนึ่งในของหวานที่เราไม่ปลื้มเท่าไหร่ เนื่องด้วยรสชาติที่หว๊าน หวาน และ
                    artificial เหลือเกิน ซึ่งเราเองยังนึกแปลกใจว่าทำไม ’กาแฟคาราเมลมัคคีเอโต้’
                    ถึงได้ฮอตฮิต และขายดี๊ ดี จนนึกอยากลอง แต่จุดอ่อนของเราคือ
                    เราเป็นคนดื่มน้ำหวานๆไม่เก่งเลย ไม่ค่อยชอบด้วยซ้ำ ซึ่งผลลัพธ์แบบไม่ต้องลุ้นก็คือ
                    ไม่ชอบเหมือนเดิม😅 ความท้าทายจึงเกิดขึ้น เราจะนำรสชาติของกาแฟคาราเมลมัคคีเอโต้
                    มาทำเป็นเจลาโต้ เริ่มต้นที่… #CoffeeArabica เราเลือกใช้กาแฟ Arabica 100% ในการทำ
                    โดยสกัดน้ำกาแฟ ให้ได้สัดส่วนที่เหมาะสม เพื่อคงรสกาแฟให้เข้มข้นมากที่สุด #Milk
                    เราใช้นมเป็นองค์ประกอบหลัก ผสมด้วย “whipping cream”
                    ซึ่งเราเลือกใช้ครีมพรีเมียมอย่าง Elle et Vire
                    เพื่อเสริมรสของกาแฟให้มีความครีมมี่ #ToffeeSauce
                    เราพลิกแพลงจากซอสคาราเมลมาเป็นซอสท็อปฟี่ค่ะ
                    ซึ่งเป็นซอสที่มีความคล้ายคลึงกับซอสคาราเมล แต่แตกต่างกันที่ขั้นตอนการทำ
                    โดยตัวซอสท็อปฟี่เราจะนำน้ำตาลและเนยใส่ลงไปเคี่ยวพร้อมกัน ทำให้ได้รสที่หวานนุ่ม
                    ไม่มีรสขม ซึ่งเข้ากับรสกาแฟเป็นที่สุด #Almond
                    เพื่อให้ได้กลิ่นอัลมอนด์ที่หอมเด่นชัด เราจึงนำอัลมอนด์โรย on top
                    และปิดจบด้วยซอสท็อปฟี่อีกชั้น
                    ความมันของอัลมอนด์และความหวานท็อปฟี่มาบรรจบกันได้อย่างลงตัว แถมยังช่วยเพิ่มความ
                    complex ของเนื้อสัมผัส เมื่อส่วนผสมทุกอย่างถูกนำมารวมร่างกัน ก็กลายเป็น Toffee
                    Coffee Gelato เป็นการแก้ปัญหาที่ต้นเหตุ เครื่องดื่มหวานๆ ไม่ชอบ พอทำเป็นเจลาโต้
                    ก็คือกินไม่หยุดดด😝
                  </p>
                </v-card-text>
                <v-card-actions class="pt-3">
                  <v-btn outlined rounded color="brown accent-4" @click="closeBael = false">
                    ปิด
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-hover>
      </v-flex>
      <v-flex xs10 lg4 pa-2>
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="344"
          >
            <v-card-title primary-title>
              <v-img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                src="@/assets/img/BlueberryYogurt.jpg"
              ></v-img>
            </v-card-title>
            <v-card-actions class="pb-6">
              <v-layout row wrap justify-center align-center>
                <v-flex xs10 lg9>
                  <v-btn
                    block
                    outlined
                    rounded
                    color="brown accent-4"
                    @click="closeFinancier = true"
                  >
                    Blueberry Yogurt Gelato
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="closeFinancier"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4 brown--text">Blueberry Yogurt Gelato</p>
                  <p class="brown--text text-left pt-4">
                    สามส่วนผสมหลักของ Blueberry Yogurt Gelato ความท้าทายอยู่ตรงที่เราใช้ Gut feeling
                    จินตนาการรสชาติของส่วนผสมแต่ละอย่าง
                    ซึ่งพอรวมกันแล้วจะต้องได้รสที่ยังคงความดั้งเดิมของวัตถุดิบแต่ละตัวเอาไว้อยู่
                    การเข้าถึงรสชาตินี้ได้ เราต้องลองผิดลองถูกอยู่หลายครา
                    จำได้ว่ารสนี้เป็นรสแรกๆที่เราเทสสูตร แต่กลับเป็นรสสุดท้ายที่ออกวางขาย😂
                    ซึ่งรสชาติที่เราจินตนาการไว้ในใจคือ... #𝑌𝑜𝑔𝑢𝑟𝑡 อย่าง “รสโยเกิร์ต” ที่เราคิดไว้
                    คือรสโยเกิร์ตที่เป็นธรรมชาติ เหมือนทาน Greek Yogurt เป็นรสชาติที่ไม่ออกรสนม
                    หรือรสเปรี้ยวมากเกินพอดี จนรู้สึกว่าเป็นนมเปรี้ยวมากกว่าจะเป็นโยเกิร์ต คำถามคือ
                    ’เราจะหาโยเกิร์ตแบบที่เราต้องการได้จากไหน’
                    นั่นทำให้เราต้องเริ่มเทสโยเกิร์ตแต่ละยี่ห้อทั้งของไทยและต่างประเทศ
                    จนสุดท้ายเราเลือกโยเกิร์ตของฝรั่งเศส
                    เพราะประทับใจในรสชาติและสามารถจูนเข้ากันกับรสที่เราต้องการได้ #𝐵𝑙𝑢𝑒𝑏𝑒𝑟𝑟𝑦
                    ต่อมาคือ ”บลูเบอร์รี่” ซึ่งดีเทลอยู่ที่ เรื่อง consistance ของรสชาติ
                    เนื่องจากบลูเบอร์รี่เป็นผลไม้สด ทำให้บางลูกมีรสเปรี้ยว บางลูกก็หวาน
                    บางครั้งก็ฝาด เราเลยชั่งใจอยู่สักพัก ว่าควรใช้บลูเบอร์รี่สดดีมั้ย
                    สุดท้ายเราตัดสินใจใช้บลูเบอร์รี่เพียวเร่เกรดพรีเมียมของฝรั่งเศสแทน
                    ซึ่งเรานำบลูเบอร์รี่ที่ได้มาทำเป็นซอสบลูเบอร์รี่เข้มข้น แล้วจึงนำมา swirl
                    กับตัวโยเกิร์ตอีกที #𝐵𝑙𝑢𝑒𝑏𝑒𝑟𝑟𝑦𝑆𝑎𝑢𝑐𝑒 ลูกเล่นสุดท้ายที่ทำให้ Blueberry Yogurt
                    Gelato ออกมาเพอร์เฟคคือตัว “ซอสบลูเบอร์รี่” ที่นำมาท็อปด้านบนของเจลาโต้อีกชั้น
                    เป็นการนำเสนอที่หลากหลาย และเพิ่มรสชาติบลูเบอร์รี่ให้เข้มข้นมากยิ่งขึ้น
                  </p>
                </v-card-text>
                <v-card-actions class="pt-3">
                  <v-btn outlined rounded color="brown accent-4" @click="closeFinancier = false">
                    ปิด
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-hover>
        <!-- <v-hover v-slot="{ hover }" open-delay="200">
            <v-card max-width="350" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
              <v-card-text data-aos="fade-up" data-aos-easing="ease-in-sine">
                <v-img src="@/assets/img/Financier.jpg"></v-img>
              </v-card-text>
            </v-card>
          </v-hover>
          <v-card
            style="position: absolute; top: 1050px; left: 947px; border-radius: 5px"
            class="mx-auto"
            max-width="290"
            height="330"
          >
            <v-card-title class="fontHeader" data-aos="fade-up" data-aos-easing="ease-in-sine">
              Financier</v-card-title
            >

            <v-card-subtitle
              class="brown--text text-left pt-4"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              Financier ขนมทานเล่นดั้งเดิมของฝรั่งเศส ที่เรานำเนยไปตั้งไฟอ่อนๆ จนตกกระกอน
              กลายเป็นสีน้ำตาลอ่อนๆ หรือที่เราเรียกว่า Brown Butter ซึ่งความพิเศษของ Brown Butter
              คือเนยจะมีรสชาติออกรส nutty เมื่อนำไปผสมกับตัวแป้งนำเข้าจากฝรั่งเศส (T45)
              จึงทำให้เนื้อขนมมีทั้งความหอม ความฉ่ำของเนย และได้รสที่นุ่มนวล อีกทั้ง Texture
              ของฟินองเซียจะมีความหนึบด้านใน แต่ขอบด้านนอกมีความกรอบเล็กน้อย
              จึงทำให้ฟินองเซียได้เป็นขนมที่มีเสน่ห์เฉพาะตัว
            </v-card-subtitle>
          </v-card> -->
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center align-center pt-10 pb-16>
      <v-flex xs10 lg4 pa-2>
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="344"
          >
            <v-card-title primary-title>
              <v-img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                src="@/assets/img/StrawberryCheesecakeCrumble.jpg"
              ></v-img>
            </v-card-title>
            <v-card-actions class="pb-6">
              <v-layout row wrap justify-center align-center>
                <v-flex xs10 lg11>
                  <v-btn outlined rounded color="brown accent-4" @click="closeCheesecake = true">
                    Strawberry Cheesecake Crumble
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="closeCheesecake"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4 brown--text">Strawberry Cheesecake Crumble</p>
                  <p class="brown--text text-left pt-4">
                    สำหรับเจลาโต้รสนี้ เราตั้งใจให้มีทั้งหวาน เปรี้ยวและเค็ม ไปพร้อมๆกัน
                    รสหวานที่ได้เกิดจากสตอเบอรี่ ที่เราใช้เทคนิคเดียวกับการทำแยม
                    เจือด้วยความเปรี้ยวบางๆของชีสเค้ก จนได้ไอศกรีมรสชาตินัว เนื้อเนียน
                    ตัดรสด้วยรสเค็มของครัมเบิ้ลกรอบๆ
                    ดังนั้นเราเชื่อว่าคนที่ชื่นชอบชีสเค้กจะสามารถเข้าใจถึงความลงตัวนี้ได้
                  </p>
                </v-card-text>
                <v-card-actions class="pt-3">
                  <v-btn outlined rounded color="brown accent-4" @click="closeCheesecake = false">
                    ปิด
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-hover>
      </v-flex>
      <v-flex xs10 lg4 pa-2>
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="344"
          >
            <v-card-title primary-title>
              <v-img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                src="@/assets/img/CrunchyCaramellow.jpg"
              ></v-img>
            </v-card-title>
            <v-card-actions class="pb-6">
              <v-layout row wrap justify-center align-center>
                <v-flex xs10 lg8>
                  <v-btn block outlined rounded color="brown accent-4" @click="closeCrunchy = true">
                    Crunchy Caramellow
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="closeCrunchy"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4 brown--text">Crunchy Caramellow</p>
                  <p class="brown--text text-left pt-4">
                    ที่มาเจลาโต้รสนี้เกิดจาก ‘ป๊อปคอร์นรสคาราเมล’ ซึ่งสายเด็กอ้วนอย่างเราชอบนักหนา
                    จะว่าหวานก็หวาน แต่พอทานแล้วเพลินเป็นที่สุด เราจึงปิ๊งเป็นไอเดีย
                    อยากลองทำเจลาโต้รสนี้ที่เป็นเบสนี้ดูสักครั้ง เริ่มจากตัว #Caramel
                    ที่เราเคี่ยวเองจนเป็นสีน้ำตาลเข้ม เพื่อให้คาราเมลมีความ caramelized
                    คือมีกลิ่นหอมจากน้ำตาลไหม้อ่อนๆ และมีรสขมปนอยู่นิดๆ ทำให้ทานแล้วไม่เลี่ยน
                    ซึ่งนั่นคือจุดเด่นของเจลาโต้รสนี้ เรียกง่ายๆคือ คาราเมลต้องถึง
                    อย่างที่พอทราบกันดีว่าเจลาโต้ของร้านเรามีความขี้เล่นอยู่แล้ว
                    เราจึงมักจะซ่อนอะไรที่เป็นเซอร์ไพรส์เสมอ เราจึงนำ <br />
                    #Macademia มาช่วยเพิ่มรสสัมผัสให้มีความ “ครั้นซี่” ผสมกับความมันของแมคคาเดเมีย
                    ที่ช่วยตัดรสหวานของคาราเมลได้เป็นอย่างดี จึงได้รสชาติกลมกล่อมมากยิ่งขึ้น
                    ปิดท้ายด้วย Macadamia Praline ที่เรานำคาราเมลมาเคลือบกับถั่วแมคคาเดเมีย
                    เพื่อให้ได้รสชาติและรสสัมผัสที่หลากหลาย รสนี้จึงมีทั้งความครีมมี่ ความหวานนัว
                    ความมัน ต๊าชมากกก
                  </p>
                </v-card-text>
                <v-card-actions class="pt-3">
                  <v-btn outlined rounded color="brown accent-4" @click="closeCrunchy = false">
                    ปิด
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-hover>
      </v-flex>
      <v-flex xs10 lg4 pa-2>
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="344"
          >
            <v-card-title primary-title>
              <v-img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                src="@/assets/img/ArtisanStrawberryCheesecake.jpg"
              ></v-img>
            </v-card-title>
            <v-card-actions class="pb-6">
              <v-layout row wrap justify-center align-center>
                <v-flex xs10 lg11>
                  <v-btn outlined rounded color="brown accent-4" @click="closeStrawberry = true">
                    Artisan Strawberry Cheesecake
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="closeStrawberry"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4 brown--text">Artisan Strawberry Cheesecake</p>
                  <p class="brown--text text-left pt-4">
                    หลายคนคงสงสัยว่าเจลาโต้รส #ArtisanStrawberryCheesecake และ
                    #StrawberryCheesecakeCrumble แตกต่างกันอย่างไร? อันที่จริงแล้ว
                    ทั้งสองรสชาติคือรสเดียวกัน ไม่ว่าจะเป็นตัววัตถุดิบที่เหมือนกัน ทั้งครีมชีส
                    แยมสตอเบอรี่ และครัมเบิ้ล เพียงแต่สิ่งที่แตกต่าง คือขั้นตอนในการทำ เรียกว่าเป็น
                    Strawberry Cheesecake เวอร์ชั่นแยกส่วน ก็ได้ค่ะ เพราะน้อง Strawberry Cheesecake
                    เราจะนำตัวแยมสตอเบอรี่ผสมในเบสครีมชีส แล้วปั่นลงในเครื่องพร้อมกัน ในขณะที่
                    Artisan Strawberry เราจะนำครีมชีสไปปั่นก่อน รอจนเนื้อฟู จึงค่อยนำแยมสตอเบอรี่ไป
                    swirl ปิดท้าย คุณจึงเห็นแยมสตอเบอรี่เป็นริ้วๆ
                    รวมถึงอัตราส่วนครีมชีสที่มากขึ้นของ Artisan Strawberry ทำให้ได้รสครีมชีสแบบเน้นๆ
                    เหมือนทานชีสเค้กสตอเบอรี่แบบจริงๆ dessert inspired สุดๆค่ะ
                  </p>
                </v-card-text>
                <v-card-actions class="pt-3">
                  <v-btn outlined rounded color="brown accent-4" @click="closeStrawberry = false">
                    ปิด
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
        <v-layout row wrap justify-center align-center pt-16>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/PineapplePassionFruit.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg8>
                    <v-btn
                      block
                      outlined
                      rounded
                      color="brown accent-4"
                      @click="closePineapple = true"
                    >
                      Pineapple Passion Fruit
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closePineapple"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Pineapple Passion Fruit</p>
                    <p class="brown--text text-left pt-4">
                      ช่วงโควิดหลายคนคงได้ยินข่าวพืชผลทางการเกษตรตกต่ำ
                      ผลไม้หน้าสวนขายกันโลละไม่กี่บาท ชาวสวนและเกษตรกรต่างเดือดร้อน
                      เนื่องจากสินค้าล้นตลาด ขายไม่ได้ราคา
                      และปัญหาเศรษฐกิจที่ทุกคนต่างรู้กันดีว่าซบเซาขนาดไหน แถมยังมีดราม่าเรื่อง food
                      waste เข้ามาเกี่ยวอีก ด้วยเหตุผลข้างต้น เราจึงได้นำเสาวรสจากสวนป้าพร
                      ซึ่งเป็นสวนแบบเกษตรอินทรีย์ในจังหวัดกระบี่ มาแปรรูปและรังสรรค์เป็นเจลาโต้รส
                      Pineapple Passion Fruit ขึ้นมา เป็นการสร้างมูลค่าให้แก่สินค้าเกษตร
                      และช่วยให้คุณป้าพอมีรายได้อย่างต่อเนื่อง มีกำลังใจในการปลูก ถึงแม้ว่า rest is
                      more เราจะเป็นเพียงร้านเจลาโต้โฮมเมดเล็กๆ ไม่ได้มีกำลังการผลิตมากมาย
                      แต่เรามีจุดมุ่งหมายและจุดยืนที่ชัดเจน
                      ว่าเราต้องการเป็นส่วนหนึ่งในการขับเคลื่อนสังคมและคุณภาพชีวิตของคนในชุมชนให้เดินหน้าต่อไปได้
                      รวมถึงส่งเสริมด้านการเกษตรของคนในพื้นที่ต่างๆ
                      สุดท้ายขอขอบคุณผู้สนับสนุนเบื้องหลัง อย่างร้าน เจ๊มะนาว ปูไข่ดองน้ำปลากวน
                      ที่ได้นำเจลาโต้รสชาตินี้ไปขายที่ร้านด้วย
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closePineapple = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/HoneyLemonSorbetto.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg8>
                    <v-btn block outlined rounded color="brown accent-4" @click="closeHoney = true">
                      Honey Lemon Sorbetto
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeHoney"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Honey Lemon Sorbetto</p>
                    <p class="brown--text text-left pt-4">
                      รสสัมผัสแรกของซอร์เบทโต้รส Honey Lemon คือ รสเปรี้ยวกลมกล่อมจากผลเลมอนสด
                      ตั้งแต่คำแรกที่ทาน แต่เมื่อคุณกลืนลงไป กลับได้รสหวานของน้ำผึ้งแทรกอยู่ในคอ
                      เปรียบเหมือน aftertaste พร้อมกลิ่นหอมของผิวเลมอนที่เรานำไป infuse ในเบส
                      ยังเป็นการเพิ่มอรรถรสความซับซ้อนของซอร์เบทโต้รสนี้ และเนื้อสัมผัสที่เนียน นุ่ม
                      ไม่เป็นเกล็ดน้ำแข็ง ช่วยให้ได้รสชาติที่เด่นชัด สมกับชื่อ Honey Lemon Sorbetto
                      จริงๆ
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeHoney = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/YuzuMAndarinSorbetto.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg9>
                    <v-btn block outlined rounded color="brown accent-4" @click="closeYuzu = true">
                      Yuzu x MAndarin Sorbetto
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeYuzu"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Yuzu x MAndarin Sorbetto</p>
                    <p class="brown--text text-left pt-4">
                      Concept : Luxury taste & Local orange Luxury taste :
                      รสพรีเมียมของส้มยุสุที่นำเข้าจากเกาหลี ( 유자 )
                      ด้วยรสชาติที่เปรี้ยวอ่อนๆละมุนๆ มีกลิ่นหอมสดชื่นที่ใครๆก็ต่างหลงรัก
                      เอกลักษณ์เฉพาะที่หาจากส้มที่ไหนไม่ได้ Local orange : ส้มแมนดาริน รสชาติหวานฉ่ำ
                      ตัดรสเปรี้ยวของส้มยุสุจนได้รสเปรี้ยวอมหวานชัดเจน
                      การนำส้มทั้งสองชนิดมาทำเจลาโต้ เราคงรสและกลิ่นของส้มไว้ทั้งหมด
                      เพื่อให้คุณได้กลิ่นอายทรอปิคอล ความมีชีวิตชีวา ความสดใส
                      เหมือนคุณได้ทานเจลาโต้เย็นๆท่ามกลางแสงอาทิตย์อบอุ่น
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeYuzu = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
       <v-layout row wrap justify-center align-center pt-16>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/SantolSorbetto.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg6>
                    <v-btn
                      block
                      outlined
                      rounded
                      color="brown accent-4"
                      @click="closeSantol = true"
                    >
                    Santol Sorbetto
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeSantol"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Fudge Brownie</p>
                    <p class="brown--text text-left pt-4">
                    กระท้อน ผลไม้ไทยที่ทำได้ทั้งอาหารคาวและขนมหวาน
              คราวนี้เราเลยนำกระท้อนที่ได้มาจากสวนคุณลุง จ.สุราษฎร์ธานี มาทำเจลาโต้รสกระท้อน
              เป็นเจลาโต้แบบซอร์เบท(sorbeto)ที่ใช้เนื้อกระท้อนเฉพาะที่เป็นปุยๆเท่านั้นทำให้รสชาติที่ได้มีความหวานฉ่ำอม
              เปรี้ยวนิดๆ และมีเนื้อกระท้อนนุ่มๆปนอยู่ด้วย ซึ่งโดยปกติของการทำเจลาโต้ซอร์เบท
              จะต้องมีน้ำเป็นส่วนประกอบเราจึงนำเนื้อกระท้อนมาคั้นน้ำเพื่อให้ได้รสกระท้อนธรรมชาติ
              ปราศจากกลิ่น และรสปรุงแต่ง
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeSantol = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/Durian.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg9>
                    <v-btn block outlined rounded color="brown accent-4" @click="closeDurian = true">
                      Durian Cheese Pie Gelato
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeDurian"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text"> Durian Cheese Pie Gelato</p>
                    <p class="brown--text text-left pt-4">
                     ทุเรียนกระดุมจากจังหวัดสุราษฎร์ธานี อำเภอบ้านนาสาร จุดเด่นคือรสหวานและเนื้อหอมอ่อนๆ
              เราเลือกนำมาทำเจลาโต้ โดยอาศัยความหวานของทุเรียนล้วนๆเป็นตัวชูรส ทานคู่กับครีมชีส
              พร้อมคัมเบิ้ล ที่เราออกแบบให้ เหมือนเป็นทาร์ตคล้ายกับชีสพาย จึงเป็นที่มาของชื่อเจลาโต้
              “รสทุเรียนชีสพาย”(Durian Cheese Pie Gelato)
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeDurian = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
      <br><br> <br>
  </v-app>
</template>
<script>
export default {
  components: {},
  data: () => ({
    closeBrownie: false,
    closeChewy: false,
    closeDuo: false,
    closeBanana: false,
    closeBael: false,
    closeFinancier: false,
    closeScone: false,
    closeCheesecake: false,
    closeCrunchy: false,
    closeStrawberry: false,
    closePineapple: false,
    closeHoney: false,
    closeYuzu: false,
    closeSantol: false,
    closeDurian: false
  })
}
</script>
<style scoped>
.background {
  background-color: #edede7;
}
</style>
